export const enseignes
 = [
    {
        src: "https://static.wixstatic.com/media/e49d9d_ccdc6093c12946eab438bc9e0f9f0018~mv2_d_5689_3798_s_4_2.jpg",
        title: "Enseigne lumineuse",
        url: "/enseigne/lumineuse",
    },
    {
        src: "https://static.wixstatic.com/media/e49d9d_90ba1c641af542869b7d69f196a33978~mv2.png",
        title: "Enseigne non lumineuse",
        url: "/pergola/non-lumineuses",
    },
]